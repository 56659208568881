<template>
  <v-container fluid>
    <InnerPageBackHeader @back="$router.go(-1)">
      <template v-slot:right>
        <p class="mb-0">
          <small><strong>Created At:</strong> {{ $momentDate(asset.created_at) }}</small>
          <small class="ml-2"><strong>Updated At:</strong> {{ $momentDateTimeHuman(asset.updated_at) }}</small>
          <v-btn
            class="ml-2"
            outlined
            dark
            small
            depressed
            color="primary"
            @click="getAsset"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </p>
      </template>
    </InnerPageBackHeader>

    <v-row dense class="mt-4">
      <!-- LEFT COL -->
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          :loading="loading"
          outlined
        >
          <v-card-text>
            <AssetSummary
              :asset="asset"
            />
          </v-card-text>
        </v-card>

        <v-card
          :loading="loading"
          class="mt-2"
          outlined
        >
          <v-card-text>
            <div>
              <div class="d-flex justify-around">
                <div class="d-block">
                  <p>Service Plans</p>
                </div>
                <div class="d-block">
                  <v-btn
                    v-user-can:UPDATE_ASSETS
                    small
                    text
                    color="primary"
                    @click="servicePlanDialog = true"
                  >
                    Change
                  </v-btn>
                </div>
              </div>
              <div
                v-for="plan in asset.service_plans"
                :key="plan.pivot.id"
                class="d-flex justify-around">
                <div class="d-block">
                  <h2 class="text-left w-full mb-2">{{ plan.name }}</h2>
                </div>
              </div>
              <AssetServicePlanEditDialog
                v-bind="{ asset }"
                :dialog.sync="servicePlanDialog"
                @update:selected-plans="getAsset"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- MIDDLE COL -->
      <v-col
        cols="12"
        sm="5"
      >
        <InnerPageNavigation
          :tabs="[
            { disabled: !$auth.userCan('READ_ASSETS'), icon: 'mdi-timeline-text', label: 'Asset Timeline', to: { name: 'assets.timeline', params: { assetId: this.assetId }} }
          ]"
        />
        <component
          v-if="asset.id"
          :is="$route.meta.innerComponent || 'AssetTimeline'"
          :asset="asset"
        />
      </v-col>

      <!-- RIGHT COL -->
      <v-col
        cols="12"
        sm="3"
      >
        <v-card outlined>
          <v-card-text>
            <AssetableSummary
              :asset="asset"
              @update:selected-assetable="getAsset"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script type="text/javascript">
import InnerPageBackHeader from '@/components/InnerPageBackHeader'
import AssetTimeline from '@/components/assets/AssetTimeline'
import AssetSummary from '@/components/assets/AssetSummary'
import AssetableSummary from '@/components/assets/AssetableSummary'
import InnerPageNavigation from '@/components/InnerPageNavigation'
import AssetServicePlanEditDialog from '@/components/assets/AssetServicePlanEditDialog'

export default {
  name: 'ShowAsset',

  components: {
    InnerPageBackHeader,
    AssetTimeline,
    AssetSummary,
    InnerPageNavigation,
    AssetableSummary,
    AssetServicePlanEditDialog
  },

  props: {
    assetId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      servicePlanDialog: false,
      loading: false,
      asset: {
        assetable: {},
        service_plans: []
      }
    }
  },

  mounted () {
    this.getAsset()
  },

  methods: {
    getAsset () {
      this.loading = true
      this.$api.get(`assets/${this.assetId}`, { params: { with: ['assetable', 'servicePlans'] }})
        .then(({ data : asset }) => {
          this.asset = asset
        })
        .catch(() => {
          //
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>
