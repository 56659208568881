<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Change Account</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >

              <v-row class="py-0">
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="account"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Account"
                    outlined
                  />
              </v-col>
            </v-row>

            <v-divider class="mb-6 mt-3" />

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="changeAccount"
          >
            Update Asset <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Asset updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the asset.
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'
import HasAssetProp from '@/mixins/HasAssetProp'
import HasForm from '@/mixins/HasForm'

export default {
  name: 'AssetableEditDialog',

  mixins: [HasDialog, HasAssetProp, HasForm],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      account: {},
      accounts: [],
      loading: false
    }
  },

  mounted () {
    this.getAccounts()
  },

  methods: {
    getAccounts() {
      this.loading = true
      this.$api.get(`accounts/${this.$auth.account.id}/children`, {
        params: {
          limit: 100
        }
      })
        .then(({ data: accounts }) => {
          this.accounts = [
            ...accounts,
            ...[ this.$auth.account ]
          ]
          this.account = this.asset.assetable.id
        })
        .finally(() => this.loading = false)
    },

    changeAccount () {
      this.loading = true
      this.$api.put(`assets/${this.asset.id}`, {
        assetable_id: this.account
      })
        .then(() => {
          this.$refs.successBar.open()
          this.$emit('update:selected-assetable', this.account)
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
