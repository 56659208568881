<template>
  <v-app-bar
    flat
    dense
  >
    <div class="d-flex justify-content-between align-items-center w-full">
      <v-icon
        left
        @click="$emit('back')"
      >
        mdi-arrow-left-circle
      </v-icon>
      <slot name="right" />
    </div>
  </v-app-bar>
</template>
<script type="text/javascript">
export default {
  name: 'InnerPageBackHeader'
}
</script>
