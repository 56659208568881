<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit Asset</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >

          <v-divider class="mb-6 mt-3" />

          <FormErrors ref="formErrors" />

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="updateAsset"
          >
            Update Asset <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Asset updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the asset.
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'AssetEditDialog',

  mixins: [HasDialog, HasForm],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    updateAsset () {
      //
    }
  }
}
</script>
