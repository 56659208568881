<template>
  <v-tabs
    :background-color="$vuetify.theme.dark ? '' : 'primary'"
    fixed-tabs
  >
    <v-tab
      v-for="(tab, key) in tabs"
      :key="key"
      :to="tab.to"
      :disabled="tab.disabled"
      >
      <v-icon
        small
        left
      >
        {{ tab.icon }}
      </v-icon> 
      {{ tab.label }}
    </v-tab>
  </v-tabs>
</template>
<script type="text/javascript">
export default {
  name: 'InnerPageNavigation',

  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
}
</script>
