<template>
  <div></div>
</template>

<script>
export default {
  name: 'AssetTimeline',

  data () {
    return {

    }
  }
}
</script>
