<template>
  <div>
    <div class="d-flex justify-around">
      <div class="d-block">
        <h2 class="text-left w-full">{{ asset.number }}</h2>
      </div>
      <div class="d-block">
        <v-btn
          v-user-can:UPDATE_ASSETS
          v-if="asset.id"
          small
          text
          color="primary"
          @click="editDialog = true"
        >
          Edit
        </v-btn>
      </div>
    </div>
    <p><small>{{ asset.id }}</small></p>

    <p class="mb-0">Manufacturer: <strong>{{ asset.manafacturer }}</strong></p>
    <p class="mb-0">Model: <strong>{{ asset.model }}</strong></p>
    <p class="mb-0">Colour: <strong>{{ asset.colour }}</strong></p>
    <p>Type: <strong>{{ asset.type_label }}</strong></p>

    <AssetEditDialog
      v-bind="{ asset }"
      :dialog.sync="editDialog"
    />
  </div>
</template>
<script>
import HasAssetProp from '@/mixins/HasAssetProp'
import AssetEditDialog from '@/components/assets/AssetEditDialog'

export default {
  name: 'AssetSummary',

  mixins: [ HasAssetProp ],

  components: {
    AssetEditDialog
  },

  data () {
    return {
      editDialog: false
    }
  }
}
</script>
