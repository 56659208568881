<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Change Service Plan</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >

              <v-row class="py-0">
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="selectedPlan"
                    :items="servicePlans"
                    item-value="id"
                    item-text="name"
                    return-object
                    chips
                    label="Selected Plans"
                    outlined
                  />
              </v-col>
            </v-row>

            <v-divider class="mb-6 mt-3" />

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="changePlan"
          >
            Update Asset <v-icon small right>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Asset updated successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue updating the asset.
    </SnackBar>
  </div>
</template>
<script>
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import HasAssetProp from '@/mixins/HasAssetProp'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'AssetServicePlanEditDialog',

  mixins: [HasDialog, HasForm, HasAssetProp],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      servicePlans: [],
      selectedPlan: {}
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.selectedPlan = this.asset.service_plans[0]
      },
    },
  },

  mounted () {
    this.getServicePlans()
  },

  computed: {
    formatSelectedPlansForApi () {
      return [{
        service_plan_id: this.selectedPlan.id
      }]
    }
  },

  methods: {
    getServicePlans () {
      this.loading = true
      this.$api.get(`accounts/${this.$auth.account.id}/service-plans`, { params: { limit: 100 } })
        .then(({ data: servicePlans }) => {
          this.servicePlans = servicePlans
        })
        .finally(() => { this.loading = false })
    },

    changePlan () {
      this.loading = true
      this.$api.put(`assets/${this.asset.id}`, {
        service_plans: this.formatSelectedPlansForApi
      })
        .then(() => {
          this.$refs.successBar.open()
          this.$emit('update:selected-plans', this.selectedPlan)
          this.closeDialog()
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
